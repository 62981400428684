.extPaymentContainer{
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 80vh;
    justify-content: space-between;
    align-items: center;
    margin: 7rem 0;
}

.confirmPaymentSuccess{
    margin: 0 auto;
    text-align: center;
}

.confirmPaymentSuccessContainer{
    margin: 0 auto;
    width: 80px;
}

.confirmPaymentSuccessContainer img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.extPaymentLogoPicture{
    width: 100%;
    height: 100%;
    object-fit: cover;
}