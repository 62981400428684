.checkoutImgContainer{
    background: var(--PRIMARY-GRADIENT);
    /* background-color: var(--PRIMARY-COLOUR); */
    border-radius: 1rem;
    padding: 1rem;
}

.checkoutDetails{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.checkoutDetails p{
    margin: 0;
}

.checkoutDetails span{
    font-weight: bolder;
    font-size: 1rem;
}

.checkoutAssetName{
    font-size: 1.2rem;
}

.checkoutPaymentItem{
    display: flex;
    justify-content: space-between;
}

.checkoutPaymentItem h4, .checkoutPaymentItem h5{
    margin: 1rem 0;
}

.checkoutPaymentItem p{
    margin: .2rem 0;
}