@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital@1&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@layer base {
  * {
    @apply border-border;
  }
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 225.2 26.6% 55.1%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 240 5.9% 90%;
    --input: 0 0% 89.8%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 0 0% 6.7%;
    --foreground: 0 0% 95%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 0 0% 15%;
    --muted-foreground: 240 5% 64.9%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 240 3.7% 15.9%;
    --input: 0 0% 14.9%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

.app-container {
  @apply px-4 w-full sm:max-w-[85%] sm:w-[1280px] mx-auto;
}

body {
  @apply text-base font-normal leading-normal text-foreground bg-background;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scrollbar-width: 6px;
}

::-webkit-scrollbar {
  @apply w-[6px] h-[6px];
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-400/40 dark:bg-neutral-600/40 rounded-full;
}

::-webkit-scrollbar-track {
  @apply bg-neutral-100 dark:bg-neutral-900 shadow-inner;
  -webkit-box-shadow: inset 0 0 12px rgba(84, 95, 125, 0.1);
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.tick-svg {
  display: block;
  height: 120px;
  width: 120px;
  color: #4caf50;
  /* SVG path use currentColor to inherit this */
}

.circle {
  stroke-dasharray: 76;
  stroke-dashoffset: 76;
  -webkit-animation: draw 0.7s forwards;
  animation: draw 0.7s forwards;
}

.tick {
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
  -webkit-animation: draw 0.5s forwards 1s;
  animation: draw 0.5s forwards 1s;
}

@-webkit-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.wrap-asset-location {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.ui-error {
  height: 100px;
  width: 100px;
}

.ui-error-circle {
  stroke-dasharray: 260.75219025px, 260.75219025px;
  stroke-dashoffset: 260.75219025px;
  animation: ani-error-circle 1.2s linear;
}
.ui-error-line1 {
  stroke-dasharray: 76;
  stroke-dashoffset: 76;
  stroke-linecap: round;
  animation: ani-error-line 0.15s 1.2s linear both;
}
.ui-error-line2 {
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
  stroke-linecap: round;
  animation: ani-error-line 0.2s 0.9s linear both;
}
@keyframes ani-error-line {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes ani-error-circle {
  0% {
    stroke-dasharray: 0, 260.75219025px;
    stroke-dashoffset: 0;
  }
  35% {
    stroke-dasharray: 120px, 120px;
    stroke-dashoffset: -120px;
  }
  70% {
    stroke-dasharray: 0, 260.75219025px;
    stroke-dashoffset: -260.75219025px;
  }
  100% {
    stroke-dasharray: 260.75219025px, 0;
    stroke-dashoffset: -260.75219025px;
  }
}

.footer-card {
  background-image: url("/public/backgroundOne.svg");
}


.ql-toolbar.ql-snow {
  border: 1px solid #eaf0f4;
  box-sizing: border-box;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 8px;
}

.ql-container.ql-snow {
  border: 1px solid #eaf0f4;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  height: 250px;
}

.ql-editor.ql-blank p {
  font-style: normal !important;
}

.image-uploading {
  position: relative;
  display: inline-block;
}

.image-uploading img {
  max-width: 98% !important;
  filter: blur(5px);
  opacity: 0.3;
}

.image-uploading::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #1e986c;
  z-index: 1;
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.list-style ol {
  list-style: decimal;
  padding: 0px 30px;
}

.list-style ul {
  list-style: disc;
  padding: 0px 30px;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

pre {
  font-size: 12px;
}

pre.background {
  display: block;
  background-color: #1d1f20;
  height: auto;
}

/* plain elements */
pre .plain {
  color: #fff;
}

/* html tags */
pre .tag {
  color: #9a6e35;
  font-weight: bold;
}

/* class tags */
pre .classTag {
  color: #ddca7e;
  font-weight: bold;
}

/* wrapped in quotes */
pre .quote {
  color: #96b38a;
  font-weight: bold;
}

/* plain text*/
pre .text {
  color: #ffffff;
  font-weight: bold;
}

code {
  overflow: auto;
}

.valid, .invalid{
  font-size: 12px;
}
